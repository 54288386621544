import { useEffect } from 'react';

const trackingIds = {
  'portalcliente.lmassinecar.com.br': 'G-0Z8KBCZV01',
  'portalcliente.audisignature.com.br': 'G-SXNQHL7K23',
  'portalcliente.rentalway.com.br': 'G-50HSV134N2',
  'portalcliente.vwsignanddrive.com.br': 'G-WNTDVTYBHJ',
  'portalcliente.lmmobilidade.com.br': 'G-5X3YTMXHW0',
};

function useGtag() {
  const domain = window.location.hostname;
  const trackingId = trackingIds[domain as keyof typeof trackingIds];

  useEffect(() => {
    if (trackingId) {
      const script1 = document.createElement('script');
      script1.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
      script1.async = true;
      document.head.appendChild(script1);

      const script2 = document.createElement('script');
      script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${trackingId}');
    `;
      document.head.appendChild(script2);
    } else {
      console.error('Nenhum ID de rastreamento encontrado para este domínio');
    }
  }, [trackingId]);

  const trackEvent = (nameEvent: string, value?: number) => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', nameEvent, {
        event_category: 'Button',
        value,
      });
    } else {
      console.warn('gtag não está disponível');
    }
  };

  return { trackEvent };
}

export default useGtag;
