import styled from 'styled-components';

export const StyledBoxNewUrl = styled.div<{ $radius: number }>`
  display: grid;
  padding: 15px 35px;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 24px;
  border-radius: ${(props) => props.$radius * 2}px;
  background: #fff9eb;
  width: 90%;

  .message-container {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 30px;

    @media (max-width: 620px) {
      grid-template-columns: 1fr;
      gap: 15px;
    }
  }

  h2 {
    color: #6f581b;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    padding-bottom: 10px;
  }

  p {
    color: #7a6532;
    font-size: 14px;
    max-width: 640px;

    a {
      color: #7a6532;
      text-decoration: underline;
      cursor: pointer;
    }

    @media (max-width: 620px) {
      max-width: 100%;
    }
  }

  @media (max-width: 620px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 425px) {
    width: 100%;
  }

  .button-link {
    background: #fff;
    border: 1px solid #cccccc;
    padding: 8px 15px;
    border-radius: 4px;
    font-size: 14px;
    color: #555555;

    display: grid;
    grid-template-columns: 1fr auto;
    gap: 10px;
    align-items: center;

    @media (max-width: 620px) {
      max-width: 160px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`;
