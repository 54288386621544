import React from 'react';
import { StyledBoxNewUrl } from './style';
import { getTheme, getProductFromUrl } from 'pages/LandingPages/utils/helpers';
import iconLink from './arrow-right.svg';
import iconWarn from './icon-warn.svg';
import useGtag from 'hooks/useGtag';
import { environmentLinks } from 'pages/LandingPages/utils/constants';

const theme = getTheme();
const environment: any = process.env.REACT_APP_ENVIRONMENT;

const NewPortal: React.FC = () => {
  const { trackEvent } = useGtag();

  const [linkNewPortal, setLinkNewPortal] = React.useState('');

  React.useEffect(() => {
    if (getProductFromUrl() === '') {
      const storageListOfPrograms: any = localStorage.getItem(
        '@FleetSolutions:listOfPrograms'
      );

      const programs = JSON.parse(storageListOfPrograms);

      if (programs?.length >= 1) {
        const link = programs[0]?.split('.')[1];
        setLinkNewPortal(environmentLinks[link][environment]);
      } else {
        setLinkNewPortal(theme.linkNewPortal);
      }
    } else {
      setLinkNewPortal(theme.linkNewPortal);
    }
  }, []);

  return (
    <StyledBoxNewUrl $radius={theme.radius}>
      <div className="message-container">
        <img src={iconWarn} alt="Ícone alerta" />
        <div>
          <h2>Temos uma novidade!</h2>
          <p>
            Uma nova versão do portal está disponível! Para acessá-lo, será
            necessário realizar um novo cadastro.{' '}
            <a
              onClick={() => trackEvent('link_new_portal_text', 1)}
              href={linkNewPortal}
              target="_blank"
            >
              Clique aqui
            </a>{' '}
            para se cadastrar no novo portal e acessar os novos recursos e
            melhorias.
          </p>
        </div>
      </div>
      <a
        onClick={() => trackEvent('link_new_portal_button', 2)}
        className="button-link"
        href={linkNewPortal}
        target="_blank"
      >
        Criar cadastro <img src={iconLink} alt="Ícone seta direita" />
      </a>
    </StyledBoxNewUrl>
  );
};

export default NewPortal;
